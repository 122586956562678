import { DataSourceParameterType, parseDataSourceParameterType, stringifyDataSourceParameterType } from '@serverfarm/rs-commons';
import classnames from 'classnames';
import logdown from 'logdown';
import moment from 'moment-timezone';
import { Maybe, Some, None } from 'monet';
import { useState } from 'react';
import * as React from 'react';
// @ts-ignore
import * as Icon from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CustomInput,
  CardBody,
  FormGroup,
  Input,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { UserRole } from '../../auth/entities';
import { State } from '../../reducers';
import { DataSourceParameter, dropdownInputOptionsFromDataSourcesList, dropdownInputOptionsFromDataSourceExecResult } from '../datasources';
import { showErrorNotification } from '../notification';
import { withClickConfirmation, DropdownInput } from '../utils';
import { DynamicInput } from '../utils/dynamicInput';
import { ServerfarmCalendar } from '../utils/serverFarmCalendar';

import { editReportParameterAdd, editReportParameterDelete, editReportParameterFunc, editReportParameterValue } from './data/actions';
import {
  ReportParameter,
  ReportParameterInitFunctionType,
  InitFnParameters,
  InitFnReturnType,
  ReportParameterInitFunctionTypeParams,
} from './entities';

const logger = logdown('component:ReportParametersEditor');

const CONVERSION_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const ParametersDropdown = (
  props: Props & {
    tabToggle: (name: string) => void;
  },
) => {
  const [tabDropdown, setTabDropdown] = useState(false);
  const tabDropdownToggle = () => setTabDropdown((prevState) => !prevState);
  return props.reportEditing.report
    .flatMap((r) => r.dataSource)
    .flatMap((ds) => ds.parameters)
    .map((dsParams) => {
      return props.reportEditing.report
        .flatMap((report) => report.parameters)
        .map((rParams) => {
          return dsParams.filter((dsParam) => !rParams.some((rParam) => rParam.name === dsParam.name));
        })
        .map((availableParameters) => {
          return (
            <Dropdown
              key="parameters-dropdown"
              id="available-parameters-dropdown"
              hidden={availableParameters.length === 0}
              isOpen={tabDropdown}
              toggle={tabDropdownToggle}
            >
              <DropdownToggle className="available-parameters-dropdown" outline nav>
                <Icon.Plus className="lg" />
              </DropdownToggle>
              <DropdownMenu>
                {availableParameters.map((v) => (
                  <DropdownItem
                    key={v.name}
                    className={`dropdown-item-param`}
                    onClick={() => {
                      props.editReportParameterAdd({
                        name: v.name,
                        initFn: None(),
                        initValue: None(),
                      });
                      props.tabToggle(v.name);
                    }}
                  >
                    {v.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          );
        })
        .orJust(<div></div>);
    })
    .orJust(<div></div>);
};

const ReportParametersEditor = (props: Props) => {
  const [activeTab, setActiveTab] = useState(
    props.reportEditing.report
      .flatMap((r) => r.parameters)
      .map((rParams) => (rParams.length > 0 ? rParams[0].name : ''))
      .orUndefined(),
  );

  const [customInputs, setCustomInputs] = useState(
    props.reportEditing.report
      .flatMap((r) => r.dataSource)
      .flatMap((r) => r.parameters)
      .map((dsParams) => {
        return dsParams.reduce((o, p: DataSourceParameter) => {
          const rp = props.reportEditing.report
            .flatMap((r) => r.parameters)
            .orJust([])
            .find((rp) => rp.name === p.name);
          o[p.name] = (rp && rp.initValue.orUndefined()) || !rp;
          return o;
        }, {} as any);
      })
      .orJust({}),
  );

  const tabToggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleCustom = (name: string) => {
    setCustomInputs({
      ...customInputs,
      [name]: !customInputs[name],
    });
  };
  const ParametersNavItems = (
    props: Props & {
      activeTab: string | undefined;
      tabToggle: (name: string) => void;
    },
  ) => {
    const CloseWithConfirmation = withClickConfirmation(Icon.XCircleFill);

    return props.reportEditing.report
      .flatMap((r) => r.parameters)
      .map((reportParams) => {
        return reportParams.map((param: ReportParameter) => {
          return (
            <NavItem key={`nav-${param.name}`}>
              <NavLink
                className={classnames({ active: props.activeTab === param.name })}
                onClick={() => {
                  props.tabToggle(param.name);
                }}
              >
                <span
                  className={props.reportEditing.report
                    .flatMap((r) => r.dataSource)
                    .flatMap((ds) => ds.parameters)
                    .flatMap((dsParams) => Maybe.fromUndefined(dsParams.find((p) => p.name === param.name)))
                    .map((dsParam) => (!dsParam.isOptional ? 'text-danger' : ''))
                    .orJust('')}
                >
                  {param.name}
                  {props.reportEditing.report
                    .flatMap((r) => r.dataSource)
                    .flatMap((ds) => ds.parameters)
                    .flatMap((dsParams) => Maybe.fromUndefined(dsParams.find((p) => p.name === param.name)))
                    .map((dsParam) => (!dsParam.isOptional ? ' *' : ''))
                    .orJust('')}
                </span>
                {'  '}
                <CloseWithConfirmation
                  className="delete-param-button text-danger label"
                  hidden={
                    !props.user.profile.role.contains(UserRole.ADMIN) ||
                    props.reportEditing.report
                      .flatMap((r) => r.dataSource)
                      .flatMap((ds) => ds.parameters)
                      .flatMap((dsParams) => Maybe.fromUndefined(dsParams.find((p) => p.name === param.name)))
                      .map((dsParam) => (!dsParam.isOptional ? true : false))
                      .orJust(false)
                  }
                  id={`delete-param-button-${param.name}`}
                  size={10}
                  onClick={() => {
                    props.editReportParameterDelete(param.name);
                    const lastTab = props.reportEditing.report
                      .flatMap((r) => r.parameters.map((rp) => (rp.length > 1 ? rp[rp.length - 2].name : '')))
                      .orJust('');
                    tabToggle(lastTab);
                  }}
                  popoverCaption="Delete Parameter?"
                  popoverOkButton="Yes"
                  popoverCancelButton="No"
                ></CloseWithConfirmation>
              </NavLink>
            </NavItem>
          );
        });
      })
      .getOrElse([<div key="no-params"></div>]);
  };

  const InitFunctionsSelector = (props: Props & { param: ReportParameter; paramType: DataSourceParameterType }) => {
    const editReportParameterFuncValueUpdate = (value: any, fnParams: any) => {
      props.editReportParameterFunc(
        props.param.name,
        value
          ? props.param.initFn.map((fnp) => {
              const fnParam = fnp.parameters.find((p) => p.name === fnParams.name);
              if (fnParam) {
                fnp.parameters.splice(fnp.parameters.indexOf(fnParam), 1, {
                  ...fnParam,
                  value,
                });
              } else {
                fnp.parameters.push({
                  name: fnParams.name,
                  value,
                });
              }
              return fnp;
            })
          : None(),
      );
    };
    return (
      <Row>
        <Col md="4">
          <Label>Function</Label>
          <Typeahead
            id={`function-selector-${props.param.name}`}
            options={[...Object.values(ReportParameterInitFunctionType).filter((k) => InitFnReturnType(k) === props.paramType)]}
            defaultSelected={props.param.initFn.map((fn) => [fn.fn]).orJust([ReportParameterInitFunctionType.NONE])}
            onChange={(selected) => {
              if (selected.length > 0 && selected[0] !== ReportParameterInitFunctionType.NONE) {
                props.editReportParameterFunc(
                  props.param.name,
                  Some(
                    props.param.initFn
                      .flatMap((ifn) => {
                        return Some({
                          ...ifn,
                          fn: selected[0],
                        });
                      })
                      .getOrElse({
                        parameters: [],
                        fn: selected[0],
                      }),
                  ),
                );
              }
            }}
          />
        </Col>
        <Col md="8">
          {InitFnParameters(props.param.initFn.map((fn) => fn.fn).orJust(ReportParameterInitFunctionType.NONE)).map((fnParams) => {
            const dynamicInitialState = props.param.initFn
              .map((fn) => {
                return fn.parameters.reduce((r, p) => {
                  if (p.name === fnParams.name) {
                    return p.value;
                  }
                  return r;
                }, '' as any);
              })
              .orUndefined();
            return (
              <Row key={`fn-parameter-value-row-${fnParams.name}`}>
                <Col md="12">
                  <Label>
                    <strong>
                      {props.param.initFn.exists((initFn) => initFn.fn === ReportParameterInitFunctionType.DATA_SOURCE_ELEMENT_ID) &&
                      fnParams.name === ReportParameterInitFunctionTypeParams.DATA_SOURCE_ELEMENT_ID__DATA_SOURCE_ID
                        ? 'dataSource'
                        : props.param.initFn.exists((initFn) => initFn.fn === ReportParameterInitFunctionType.DATA_SOURCE_ELEMENT_ID) &&
                          fnParams.name === ReportParameterInitFunctionTypeParams.DATA_SOURCE_ELEMENT_ID__DEFAULT_ELEMENT_ID
                        ? 'defaultElement'
                        : fnParams.name}
                    </strong>
                  </Label>
                  {props.param.initFn.exists((initFn) => initFn.fn === ReportParameterInitFunctionType.DATA_SOURCE_ELEMENT_ID) ? (
                    <DropdownInput
                      selected={{ id: dynamicInitialState }}
                      onSelect={(dsElement) => {
                        if (!dsElement) {
                          return;
                        }
                        editReportParameterFuncValueUpdate(dsElement.id, fnParams);
                      }}
                      updateOptions={
                        fnParams.name === ReportParameterInitFunctionTypeParams.DATA_SOURCE_ELEMENT_ID__DATA_SOURCE_ID
                          ? dropdownInputOptionsFromDataSourcesList
                          : dropdownInputOptionsFromDataSourceExecResult(
                              props.param.initFn
                                .flatMap(
                                  (fnp): Maybe<string> => {
                                    const fnParam = fnp.parameters.find(
                                      (p) => p.name === ReportParameterInitFunctionTypeParams.DATA_SOURCE_ELEMENT_ID__DATA_SOURCE_ID,
                                    );
                                    return fnParam ? Some(fnParam.value as string) : None();
                                  },
                                )
                                .orUndefined() as string,
                            )
                      }
                      showErrorNotification={props.showErrorNotification}
                    />
                  ) : (
                    <DynamicInput
                      placeholder={fnParams.name}
                      key={`fn-parameter-value-${fnParams.name}`}
                      id={`fn-parameter-value-${fnParams.type}-${fnParams.name}`}
                      className={`fn-parameter-value-${fnParams.type}-${fnParams.name}`}
                      type={[DataSourceParameterType.INT, DataSourceParameterType.FLOAT].includes(fnParams.type) ? 'number' : 'text'}
                      hidden={![DataSourceParameterType.STRING, DataSourceParameterType.INT, DataSourceParameterType.FLOAT].includes(fnParams.type)}
                      initialState={dynamicInitialState}
                      onChange={(value) => {
                        if (value === dynamicInitialState) {
                          return;
                        }
                        editReportParameterFuncValueUpdate(value, fnParams);
                      }}
                    />
                  )}
                  <div hidden={![DataSourceParameterType.DATE_TIME].includes(fnParams.type)}>
                    <ServerfarmCalendar
                      id={`calendar-fn-param-${fnParams.name}`}
                      showTimezone={true}
                      onReset={() => {
                        props.editReportParameterFunc(props.param.name, None());
                      }}
                      onSave={(date, timezone) => {
                        if (moment(date) === moment(dynamicInitialState)) {
                          return;
                        }
                        props.editReportParameterFunc(
                          props.param.name,
                          date
                            ? props.param.initFn.map((fnp) => {
                                const fnParam = fnp.parameters.find((p) => p.name === fnParams.name);
                                if (fnParam) {
                                  fnp.parameters.splice(fnp.parameters.indexOf(fnParam), 1, {
                                    ...fnParam,
                                    value: date.toISOString(),
                                  });
                                }
                                return fnp;
                              })
                            : None(),
                        );
                      }}
                      value={None()}
                      key={`fn-calendar-param-${fnParams.name}`}
                    />
                  </div>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    );
  };

  const ParametersTabs = (props: Props & { customInputs: any }) => {
    return props.reportEditing.report
      .flatMap((r) => r.parameters)
      .map((reportParams) => {
        return reportParams.map((param: ReportParameter) => {
          const dsParam = props.reportEditing.report
            .flatMap((r) => r.dataSource)
            .flatMap((r) => r.parameters)
            .flatMap((dsParams) => Maybe.fromUndefined(dsParams.find((p) => p.name === param.name)));
          const dsParamType = dsParam.map((p) => p.type).getOrElse(DataSourceParameterType.NONE);
          logger.info('OPENING TAB', param, dsParam, props.customInputs);
          return (
            <TabPane className={`tab-pane-param tab-pane-param-${param.name}`} key={`tab-id-${param.name}`} tabId={param.name}>
              <Row>
                <Col sm="12">
                  <Card body>
                    <CardBody>
                      <Form>
                        <FormGroup check inline>
                          <CustomInput
                            type="checkbox"
                            id={`report-parameter-custom-value-${param.name}`}
                            checked={props.customInputs[param.name]}
                            onChange={() => toggleCustom(param.name)}
                            label="Custom value"
                          />
                        </FormGroup>
                      </Form>
                      <Form>
                        <Input
                          id={`report-parameter-value-${dsParamType}-${param.name}`}
                          className={`report-parameter-value-${dsParamType}-${param.name}`}
                          type={[DataSourceParameterType.INT, DataSourceParameterType.FLOAT].includes(dsParamType) ? 'number' : 'text'}
                          hidden={
                            !props.customInputs[param.name] ||
                            ![
                              DataSourceParameterType.ARRAY_OF_STRINGS,
                              DataSourceParameterType.ARRAY_OF_INTS,
                              DataSourceParameterType.ARRAY_OF_FLOATS,
                              DataSourceParameterType.STRING,
                              DataSourceParameterType.INT,
                              DataSourceParameterType.FLOAT,
                            ].includes(dsParamType)
                          }
                          value={param.initValue.map((v) => stringifyDataSourceParameterType(dsParamType, v.value)).orUndefined()}
                          onChange={(e) =>
                            props.editReportParameterValue(
                              param.name,
                              Some({
                                type: dsParamType,
                                value: parseDataSourceParameterType(dsParamType, e.target.value),
                              }),
                            )
                          }
                        />
                        <div hidden={!props.customInputs[param.name] || ![DataSourceParameterType.DATE_TIME].includes(dsParamType)}>
                          <ServerfarmCalendar
                            id={`calendar-${param.name}`}
                            showTimezone={true}
                            timezone={param.initValue.map((p) => p.extra || '').orJust('')}
                            onReset={() => {
                              logger.log(`date reset`);
                              props.editReportParameterValue(param.name, None());
                            }}
                            onSave={(date, timezone) => {
                              const localDate = moment(date).format(CONVERSION_DATE_TIME_FORMAT);
                              const utcDate = (timezone ? moment.tz(localDate, timezone).toISOString() : date?.toISOString()) as string;
                              logger.log(`save date ${utcDate}`);
                              props.editReportParameterValue(
                                param.name,
                                date
                                  ? Some({
                                      type: dsParamType,
                                      value: utcDate,
                                      extra: timezone,
                                    })
                                  : None(),
                              );
                            }}
                            value={param.initValue.map((v) =>
                              moment((v.extra ? moment(v.value).tz(v.extra) : moment(v.value)).format(CONVERSION_DATE_TIME_FORMAT)).toDate(),
                            )}
                            key={`calendar-${param.name}`}
                          />
                        </div>
                        <div hidden={props.customInputs[param.name]}>
                          <InitFunctionsSelector {...props} param={param} paramType={dsParamType} />
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          );
        });
      })
      .getOrElse([<div key="no-tab"></div>]);
  };

  return (
    <div>
      <Nav tabs>
        {ParametersNavItems({ ...props, activeTab, tabToggle })}
        <NavItem>
          <ParametersDropdown {...props} tabToggle={tabToggle} />
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>{ParametersTabs({ ...props, customInputs: customInputs })}</TabContent>
    </div>
  );
};

class ReportParameterEditor extends React.Component<Props> {
  render() {
    return <ReportParametersEditor {...this.props} />;
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      editReportParameterAdd,
      editReportParameterValue,
      editReportParameterFunc,
      editReportParameterDelete,
      showErrorNotification,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  companiesListing: state.companiesListing,
  reportEditing: state.reportEditing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);
type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(ReportParameterEditor);
